<template>
    <div>
        <svg width="100%" height="100%" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.87127 0.916707C2.39474 0.916707 2.84007 1.48774 2.89671 2.19354H0.847772C0.904416 1.48774 1.34976 0.916707 1.87127 0.916707ZM3.30884 2.19354C3.24634 1.27139 2.62718 0.542526 1.87127 0.542526C1.11732 0.542526 0.498146 1.27139 0.435642 2.19354H0.140701V5.60909H3.60378V2.19354H3.30884Z"
                  fill="black"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'LockProtocol',
    }
</script>

<style scoped>
    svg {
        margin-left: 10px;
    }
</style>