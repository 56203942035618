<template>
    <div>
        <svg width="100%" height="110%" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <text id="text297146-5" fill="black" xml:space="preserve" style="white-space: pre"
                              font-family="Roboto" font-size="2.5" font-weight="bold" letter-spacing="0em"><tspan x="4" y="0">Arm</tspan></text>
            <path d="M11.4536 5.08413C11.4536 7.75662 9.06675 9.92191 6.12322 9.92191C3.18165 9.92191 0.794769 7.75662 0.794769 5.08413C0.794769 2.41165 3.18165 0.246353 6.12322 0.246353C9.06675 0.246353 11.4536 2.41165 11.4536 5.08413Z"
                  fill="#1B3978"/>
            <path d="M9.32264 5.08414C9.32264 6.68727 7.89091 7.98715 6.12323 7.98715C4.35751 7.98715 2.92577 6.68727 2.92577 5.08414C2.92577 3.481 4.35751 2.18112 6.12323 2.18112C7.89091 2.18112 9.32264 3.481 9.32264 5.08414Z"
                  fill="#C1C1C6"/>
            <path d="M6.12323 2.29991C7.86747 2.29991 9.28357 3.56789 9.31873 5.14442C9.31873 5.12314 9.32264 5.10364 9.32264 5.08414C9.32264 3.481 7.89091 2.18112 6.12323 2.18112C4.35751 2.18112 2.92577 3.481 2.92577 5.08414C2.92577 5.10364 2.92967 5.12314 2.92967 5.14442C2.96483 3.56789 4.38094 2.29991 6.12323 2.29991Z"
                  fill="#F0F0F5"/>
            <path d="M6.12323 7.91268C4.37118 7.91268 2.95117 6.63231 2.92773 5.04691C2.92773 5.05932 2.92577 5.07173 2.92577 5.08414C2.92577 6.68728 4.35751 7.98715 6.12323 7.98715C7.89091 7.98715 9.32264 6.68728 9.32264 5.08414C9.32264 5.07173 9.32067 5.05932 9.32067 5.04691C9.29723 6.63231 7.87724 7.91268 6.12323 7.91268Z"
                  fill="#929296"/>
            <path d="M6.12322 9.76406C3.21095 9.76406 0.843614 7.63957 0.796736 5.00433C0.796736 5.03093 0.794769 5.05752 0.794769 5.08412C0.794769 7.75661 3.18165 9.9219 6.12322 9.9219C9.06675 9.9219 11.4536 7.75661 11.4536 5.08412C11.4536 5.05752 11.4517 5.03093 11.4517 5.00433C11.4028 7.63957 9.03745 9.76406 6.12322 9.76406Z"
                  fill="#142957"/>
            <path d="M6.12322 0.374021C9.04331 0.374021 11.4126 2.50563 11.4517 5.14797C11.4517 5.12668 11.4536 5.10541 11.4536 5.08413C11.4536 2.41165 9.06675 0.246353 6.12322 0.246353C3.18165 0.246353 0.794769 2.41165 0.794769 5.08413C0.794769 5.10541 0.796736 5.12668 0.796736 5.14797C0.833848 2.50563 3.20509 0.374021 6.12322 0.374021Z"
                  fill="#244CA1"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'Prime',
    }
</script>

<style scoped>

</style>

<script>
    export default {
        name: 'Arm',
    }
</script>

<style scoped>

</style>