import { render, staticRenderFns } from "./LockProtocol.vue?vue&type=template&id=4340cff6&scoped=true"
import script from "./LockProtocol.vue?vue&type=script&lang=js"
export * from "./LockProtocol.vue?vue&type=script&lang=js"
import style0 from "./LockProtocol.vue?vue&type=style&index=0&id=4340cff6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4340cff6",
  null
  
)

export default component.exports