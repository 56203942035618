<template>
    <div>
        <svg width="100%" height="100%" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4536 5.16862C11.4536 7.8411 9.06675 10.0082 6.12322 10.0082C3.18165 10.0082 0.794769 7.8411 0.794769 5.16862C0.794769 2.49613 3.18165 0.330841 6.12322 0.330841C9.06675 0.330841 11.4536 2.49613 11.4536 5.16862Z"
                  fill="#00AEFF"/>
            <path d="M9.32264 5.16862C9.32264 6.77176 7.89091 8.07166 6.12323 8.07166C4.35751 8.07166 2.92577 6.77176 2.92577 5.16862C2.92577 3.56548 4.35751 2.26561 6.12323 2.26561C7.89091 2.26561 9.32264 3.56548 9.32264 5.16862Z"
                  fill="#C1C1C6"/>
            <path d="M6.12323 2.38618C7.86747 2.38618 9.28357 3.65417 9.31873 5.22894C9.31873 5.20943 9.32264 5.1899 9.32264 5.16862C9.32264 3.56548 7.89091 2.26561 6.12323 2.26561C4.35751 2.26561 2.92577 3.56548 2.92577 5.16862C2.92577 5.1899 2.92967 5.20943 2.92967 5.22894C2.96483 3.65417 4.38094 2.38618 6.12323 2.38618Z"
                  fill="#F0F0F5"/>
            <path d="M6.12323 7.99714C4.37118 7.99714 2.95117 6.71853 2.92773 5.13136C2.92773 5.14377 2.92577 5.15618 2.92577 5.16859C2.92577 6.7735 4.35751 8.07339 6.12323 8.07339C7.89091 8.07339 9.32264 6.7735 9.32264 5.16859C9.32264 5.15618 9.32067 5.14377 9.32067 5.13136C9.29723 6.71853 7.87724 7.99714 6.12323 7.99714Z"
                  fill="#929296"/>
            <path d="M6.12322 9.84857C3.21095 9.84857 0.843614 7.72405 0.796736 5.08881C0.796736 5.11541 0.794769 5.14201 0.794769 5.16861C0.794769 7.84109 3.18165 10.0082 6.12322 10.0082C9.06675 10.0082 11.4536 7.84109 11.4536 5.16861C11.4536 5.14201 11.4517 5.11541 11.4517 5.08881C11.4028 7.72405 9.03745 9.84857 6.12322 9.84857Z"
                  fill="#142957"/>
            <path d="M6.12322 0.458536C9.04331 0.458536 11.4126 2.59014 11.4517 5.23248C11.4517 5.2112 11.4536 5.1899 11.4536 5.16862C11.4536 2.49613 9.06675 0.330841 6.12322 0.330841C3.18165 0.330841 0.794769 2.49613 0.794769 5.16862C0.794769 5.1899 0.796736 5.2112 0.796736 5.23248C0.833848 2.59014 3.20509 0.458536 6.12322 0.458536Z"
                  fill="#244CA1"/>
            <path d="M5.99238 6.89943L4.85559 5.27677L5.08216 5.14732L5.91813 6.34257L6.98658 3.51227L7.23854 3.59207L5.99238 6.89943Z"
                  fill="black"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'CheckAir',
    }
</script>

<style scoped>

</style>