<template>
    <div class="injector-enlarged-panel-wrapper">
        <div v-for="(item, index) in items" class="img-text-row">
            <component :is="item.icon"  class="svg-icon-component"></component>
            <span style="white-space: pre-line">{{item.description}}</span>
        </div>
    </div>
</template>

<script>
    import CheckAir from "@/components/injectors/EnlargedPanel/icons/CheckAir"
    import Arm from "@/components/injectors/EnlargedPanel/icons/Arm"
    import LockProtocol from "@/components/injectors/EnlargedPanel/icons/LockProtocol"

    export default {
        name: "CheckAirAndLockArea",
        data() {
            return {
                items: [
                    {
                        icon: CheckAir,
                        description: 'Значок проверки на наличие воздуха, подсвечивается после подтверждения оператором того, что была выполнена проверка наличия воздуха в линии введения жидкости.'
                    },
                    {
                        icon: Arm,
                        description: 'Кнопка Arm, активирует инъектор. Подсвечивается после активации инъектора.'
                    },
                    {
                        icon: LockProtocol,
                        description: 'Обозначает, что протокол заблокирован. Подсвечивается после блокировки протокола.'
                    }
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/scss/components/injector-enlarged-panel-wrappers.scss";


</style>